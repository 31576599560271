import React from 'react'
import Header from './components/Header'
import Slider from './components/Slider'
import Services from './components/Services'
// import Maitrise from './components/Maitrise'
import References from './components/References'
import Realisations from './components/Realisations'
// import Temoignages from './components/Temoignages'
import FAQ from './components/FAQ'
import Contact from './components/Contact'
import Footer from './components/Footer'

import ReactGA from 'react-ga'

import './assets/scss/styles.scss'
import './assets/scss/mediaqueries.scss'

function App() {
    const TRACKING_ID = 'G-8DW5P6B83B'
    ReactGA.initialize(TRACKING_ID)
    return (
        <>
            <Header />
            <Slider />
            <Services />
            {/* <Maitrise /> */}
            <References />
            <Realisations />
            {/* <Temoignages /> */}
            <FAQ />
            <Contact />
            <Footer />
        </>
    )
}

export default App
