import React from 'react'

import Sanofi from '../assets/images/logo_sanofi.svg'
import Novo from '../assets/images/logo_novonordisk.svg'
import Servier from '../assets/images/logo_servier.svg'
import Magpharm from '../assets/images/logo_magpharm.svg'
import Pharmalliance from '../assets/images/logo_pharmalliance.svg'
import Fraterrazes from '../assets/images/logo_fraterrazes.svg'
import Genericlab from '../assets/images/logo_genericlab.svg'
import Airp from '../assets/images/logo_airp.png'
import Alvityl from '../assets/images/logo_alvityl.svg'
import Solyne from '../assets/images/logo_solyne.svg'
import Phytopharm from '../assets/images/logo_phytopharm.svg'
import Ysana from '../assets/images/logo_ysana.svg'
import Cima from '../assets/images/logo_cima.svg'
import Csa from '../assets/images/logo_csa.svg'
import Siphal from '../assets/images/logo_siphal.svg'
import Snapo from '../assets/images/logo_snapo.svg'

function References() {
    return (
        <div className="references">
            <div className="references_cont page_cont">
                <div className="description">
                    <h2 className="title">
                        Les acteurs de la santé qui nous font déjà confiance
                    </h2>
                    <p className="subtitle">
                        Nous comptons parmi nos clients différents acteurs du
                        secteur de la santé, dont d’importants laboratoires
                        pharmaceutiques.
                    </p>
                    <p className="subtitle">
                        En travaillant avec autant d’acteurs, des laboratoires
                        aux marques, en passant pas les événements, nous avons
                        construit une expertise dans le domaine du Pharma, et
                        nous avons acquis une parfaite connaissance de ses
                        particularités.
                    </p>
                </div>
                <div className="refs">
                    <div className="block">
                        <h4
                            style={{
                                backgroundColor: '#15FF99',
                                color: '#2D287E',
                            }}
                        >
                            Laboratoires pharmaceutiques
                        </h4>
                        <div
                            className="logos"
                            style={{ borderLeft: '1px solid #15FF99' }}
                        >
                            <div>
                                <img src={Sanofi} alt="Sanofi" />
                            </div>
                            <div>
                                <img src={Novo} alt="Novonordisk" />
                            </div>
                            <div>
                                <img src={Servier} alt="Servier" />
                            </div>
                            <div>
                                <img src={Magpharm} alt="Magpharm" />
                            </div>
                            <div>
                                <img src={Pharmalliance} alt="Pharmalliance" />
                            </div>
                            <div>
                                <img src={Fraterrazes} alt="Fraterrazes" />
                            </div>
                            <div>
                                <img src={Genericlab} alt="Genericlab" />
                            </div>
                            <div>
                                <img src={Airp} alt="airp" />
                            </div>
                        </div>
                    </div>
                    <div className="block">
                        <h4
                            style={{
                                backgroundColor: '#15C0FF',
                            }}
                        >
                            Marques
                        </h4>
                        <div
                            className="logos"
                            style={{ borderLeft: '1px solid #15C0FF' }}
                        >
                            <div>
                                <img src={Alvityl} alt="Alvityl" />
                            </div>
                            <div>
                                <img src={Solyne} alt="Solyne" />
                            </div>
                            <div>
                                <img src={Phytopharm} alt="Phytopharm" />
                            </div>
                            <div>
                                <img src={Ysana} alt="Ysana" />
                            </div>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="block double">
                            <h4
                                style={{
                                    backgroundColor: '#E34AFF',
                                }}
                            >
                                Établissements de soins
                            </h4>
                            <div
                                className="logos"
                                style={{ borderLeft: '1px solid #E34AFF' }}
                            >
                                <div>
                                    <img
                                        src={Cima}
                                        alt="Centre d'imagerie médicale d'alger"
                                    />
                                </div>
                                <div>
                                    <img
                                        src={Csa}
                                        alt="Centre sidi abdellah cancérologie"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="block single">
                            <h4
                                style={{
                                    backgroundColor: '#6615FF',
                                }}
                            >
                                Événements
                            </h4>
                            <div
                                className="logos"
                                style={{ borderLeft: '1px solid #6615FF' }}
                            >
                                <div>
                                    <img
                                        src={Siphal}
                                        alt="Siphal Salon international de la pharmacie en Algérie"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="block single">
                            <h4
                                style={{
                                    backgroundColor: '#C12A6A',
                                }}
                            >
                                Associations et syndicats
                            </h4>
                            <div
                                className="logos"
                                style={{ borderLeft: '1px solid #C12A6A' }}
                            >
                                <div>
                                    <img src={Snapo} alt="Snapo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default References
