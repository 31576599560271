import React from 'react'

const RealisationRS = (props) => {
    return (
        <div
            className={`realisation ${props.dimension}`}
            style={{
                backgroundColor: props.backgroundColor,
                backgroundPosition: props.backgroundPosition,
                backgroundSize: props.backgroundSize,
            }}
        >
            <div className="imagereal imgdisplay">
                <div className="cover">
                    <div>
                        <img src={props.cover} alt={props.title} />
                    </div>
                    <img src={props.cover} alt="" className="relief" />
                </div>
                <div className="posts">
                    <div className="post1">
                        <div>
                            <img src={props.img1} alt={props.title} />
                        </div>
                        <img src={props.img1} alt="" className="relief" />
                    </div>
                    <div className="post2">
                        <div>
                            <img src={props.img2} alt={props.title} />
                        </div>
                        <img src={props.img2} alt="" className="relief" />
                    </div>
                    <div className="post3">
                        <div>
                            <img src={props.img3} alt={props.title} />
                        </div>
                        <img src={props.img3} alt="" className="relief" />
                    </div>
                </div>
            </div>

            <h3>{props.title}</h3>
            <p>{props.desc}</p>
        </div>
    )
}

export default RealisationRS
