import React, { useState } from 'react'

import plus from '../assets/images/plus.svg'
import moins from '../assets/images/moins.svg'

const Question = (props) => {
    const [subQuestion, setSubQuestion] = useState(false)
    const showSubQuestion = () => setSubQuestion(!subQuestion)

    return (
        <div
            className="question"
            style={
                !subQuestion
                    ? { backgroundColor: props.color }
                    : { backgroundColor: '#F5F5F5' }
            }
            onClick={showSubQuestion}
        >
            <div className="disp">
                <h4>{props.title}</h4>
                <span>
                    {!subQuestion ? (
                        <img src={plus} alt="plus" />
                    ) : (
                        <img src={moins} alt="moins" />
                    )}
                </span>
            </div>
            <div
                className="details_cont"
                style={!subQuestion ? { opacity: '0' } : { opacity: '1' }}
            >
                {subQuestion && <div className="details">{props.children}</div>}
            </div>
        </div>
    )
}

export default Question
