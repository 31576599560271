import React from 'react'

import image_services from '../assets/images/image_services.png'
import arrow from '../assets/images/arrow.svg'

import groupe162 from '../assets/images/Groupe 162.svg'
import groupe163 from '../assets/images/Groupe 163.svg'
import groupe164 from '../assets/images/Groupe 164.svg'
import groupe165 from '../assets/images/Groupe 165.svg'
import groupe166 from '../assets/images/Groupe 166.svg'
import groupe167 from '../assets/images/Groupe 167.svg'
import groupe168 from '../assets/images/Groupe 168.svg'

function Services() {
    return (
        <div className="services">
            <div className="services_cont page_cont">
                <div className="description">
                    <div className="content">
                        <h2 className="title">
                            Nos services, pour tous vos besoins en digital
                        </h2>
                        <p className="subtitle">
                            Que vous soyez chef de produit, responsable
                            marketing ou digital manager, vous avez besoin plus
                            que jamais d’un partenaire digital fiable, avec un
                            ADN digital, sur lequel vous pouvez compter
                            pleinement pour répondre à vos besoins croissants en
                            digital et vous aider à atteindre vos objectifs
                            auprès des professionnels de la santé.
                        </p>
                    </div>
                    <div className="arrow">
                        <img src={arrow} alt="" />
                    </div>
                    <div className="image">
                        <div className="im">
                            <img src={image_services} alt="shiftin agency" />
                            <img
                                src={image_services}
                                alt="shiftin agency"
                                className="relief"
                            />
                        </div>
                    </div>
                </div>
                <div className="bull1"></div>
                <div className="bull2"></div>
                <div className="bull3"></div>
            </div>
            <div className="services_icons">
                <div className="services_icons_cont">
                    <div className="flex">
                        <div>
                            <img src={groupe162} alt="" />
                            <h4>
                                Conception d’aides de visite (ADV){' '}
                                {/* compatibles
                                Veeva */}
                            </h4>
                        </div>
                        <div>
                            <img src={groupe163} alt="" />
                            <h4>
                                Création de plateformes web et digitales (site
                                web, landing page)
                            </h4>
                        </div>
                        <div>
                            <img src={groupe164} alt="" />
                            <h4>Création d’applications mobiles</h4>
                        </div>
                    </div>
                    <div className="flex">
                        <div>
                            <img src={groupe165} alt="" />
                            <h4>Stratégie de communication digitale</h4>
                        </div>
                        <div>
                            <img src={groupe166} alt="" />
                            <h4>Campagne de communication</h4>
                        </div>
                        <div>
                            <img src={groupe167} alt="" />
                            <h4>Motion Design (2D et 3D)</h4>
                        </div>
                        <div>
                            <img src={groupe168} alt="" />
                            <h4>Emailing</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services
