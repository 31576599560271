import React from 'react'

import logo from './../assets/images/logo_shiftin_healthcare_w.svg'
import fb from './../assets/images/24x24-facebook.svg'
import instagram from './../assets/images/24x24-instagram.svg'
import linkedin from './../assets/images/24x24-linkedin.svg'
// import veeva from './../assets/images/veeva.svg'
import shiftin from './../assets/images/logo_shiftin.svg'

function Footer() {
    return (
        <footer>
            <div className="foot_top">
                <div className="footer page_cont">
                    <div className="logo_container">
                        <img
                            src={logo}
                            alt="Shiftin Healthcare"
                            className="logo"
                        />
                        {/* <div className="sep"></div>
                        <img
                            src={veeva}
                            alt="Veeva content partner"
                            className="veeva"
                        /> */}
                    </div>
                    <div className="rs">
                        <p>Suivez-nous</p>
                        <a
                            href={'https://www.facebook.com/shiftinagency'}
                            target={'_blank'}
                            rel={'noreferrer'}
                        >
                            <img src={fb} alt="Facebook" />
                        </a>
                        <a
                            href={'https://www.instagram.com/shiftinagency/'}
                            target={'_blank'}
                            rel={'noreferrer'}
                        >
                            <img src={instagram} alt="Instagram" />
                        </a>
                        <a
                            href={
                                'https://www.linkedin.com/company/shiftinagency/'
                            }
                            target={'_blank'}
                            rel={'noreferrer'}
                        >
                            <img src={linkedin} alt="Linkedin" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="foot_bottom">
                <div className="footer page_cont">
                    <div className="signature">
                        <p>© 2022 SHIFTIN Healthcare - Tous droits réservés.</p>
                    </div>
                    <div className="by">
                        <p>Une filiale de l'agence</p>
                        <a
                            href={'https://www.shiftin.co'}
                            target={'_blank'}
                            rel={'noreferrer'}
                        >
                            <img src={shiftin} alt="SHIFTIN" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
