import React from 'react'

const RealisationPage = (props) => {
    return (
        <div
            className={`realisation ${props.dimension}`}
            style={{
                backgroundColor: props.backgroundColor,
                backgroundPosition: props.backgroundPosition,
                backgroundSize: props.backgroundSize,
            }}
        >
            {props.type === 'scroll' ? (
                <div className="imagereal imgscroll">
                    <div className="img">
                        <img src={props.img} alt={props.title} />
                    </div>
                    <img src={props.img} alt="" className="relief" />
                </div>
            ) : (
                <div className="imagereal imglogo">
                    <div className="img">
                        <img src={props.img} alt={props.title} />
                        <div
                            className="logocont"
                            style={{
                                backgroundColor: props.color,
                            }}
                        >
                            <img src={props.logo} alt={props.title} />
                        </div>
                    </div>
                    <div className="linkreal">
                        <a href="sasa">Découvrir le projet</a>
                    </div>
                    <img src={props.img} alt="" className="relief" />
                </div>
            )}

            <h3>{props.title}</h3>
            <p>{props.desc}</p>
        </div>
    )
}

export default RealisationPage
